<!-- eslint-disable vue/valid-v-for -->
<template>
    <section>

        <table class="table table-bordered table-stripped" style="width:100%;">
            <thead>
                <th>Réf</th>
                <th>Date publication</th>
                <th>Description</th>
                <th>Propriétaire</th>
                <th>Valide au</th>
                <th>Détail</th>
            </thead>
            <tbody>
            <tr v-if="demandes.length < 1"><td colspan="6" style="padding:100px 0px;">No data</td></tr>
                <tr v-for="demande in demandes" :key="demandes.id">
                <td> {{ demande.ref }} </td>
                <td> {{ demande.datec }} </td>
                <td> {{ demande.desc }} </td>
                <td> <span  v-if="user > 0"> {{ demande.proprietaire }} </span>
                  <span v-if="user  < 1"> <font-awesome-icon icon="fa-lock" style="color:orangered"/></span>
                </td>
                <td> {{ demande.datev }} </td>
                <td> 
                
                <font-awesome-icon icon="fa-bars" v-if="user > 0"/>
                <font-awesome-icon icon="fa-lock" v-if="user < 1" style="color:orangered"/>
                    </td>
                </tr>
            </tbody>
        </table>

<nav aria-label="Page navigation example">
  <ul class="pagination justify-content-center">
    <li class="page-item" @click="$emit('fromdebut')">
      <span class="page-link"> <font-awesome-icon icon="fa-angle-double-left" /> </span>
    </li>
    <li class="page-item" @click="$emit('toprevpage')">
      <span class="page-link"> <font-awesome-icon icon="fa-angle-left" /></span>
    </li>
    <li style="padding: 0px 15px;">  Afficher : </li>
    <li class="page-item">
    <select class="form-control" @change="$emit('setParPage', parpage)" v-model="parpage">
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
    </select>  
    </li>
   <li style="padding: 0px 15px;">sur : {{ totalRecord }}</li>
    <li class="page-item">
      <span class="page-link"  @click="$emit('tonextpage')"> <font-awesome-icon icon="fa-angle-right" /> </span>
    </li>
    <li class="page-item">
      <span class="page-link" @click="$emit('tolastpage')"> <font-awesome-icon icon="fa-angle-double-right" /> </span> 
    </li>
  </ul>
</nav>

    </section>
</template>
<script>


export default {
// eslint-disable-next-line vue/multi-word-component-names
name : 'Demandestable',
data() {
return {
    email:"",
    password:"",
    parpage:10
}
},
props: {
    demandes: [],
    title:String,
    user:Number,
    page:String,
    totalRecord:Number    
  }
}

</script>

<style>

</style>