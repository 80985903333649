<template>
    <section>

        <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Connexion</p>
                        <button
                            type="button"
                            class="delete"
                            @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field label="Email">
                            <b-input
                                type="email"
                                :value="email"
                                placeholder="Your email"
                                v-model="email"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Mot de passe">
                            <b-input
                                type="password"
                                :value="password"
                                v-model="password"
                                password-reveal
                                placeholder="Your password"
                                required>
                            </b-input>
                        </b-field>

                    
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Se connecter"
                            @click="login" />
                        <b-button
                            label="Fermer"
                            type="is-primary" 
                            @click="$emit('close')"/>
                    </footer>
                </div>
            </form>
            
    </section>
    
</template>

<script>
import axios from "axios";

 export default {
// eslint-disable-next-line vue/multi-word-component-names
name : 'Loginform',
data() {

return {
    email:"",
    password:""
}
},
methods:{
    login(){

        axios.post("http://localhost:1515/login", {"email":this.email,"pswd":this.password}, {withCredentials: true,credentials: "include"}).then((data)=> {
            let user = data["data"];
            if(user > 0) { location.reload();  } else {
                this.$buefy.snackbar.open({
                    message: "Adresse mail et/ou mot de passe incorrecte",
                    type: 'is-danger',
                    position: 'is-bottom',
                    actionText: 'Reéssayer',
                    indefinite: true,
                    onAction: () => {
                        this.login();
                    }
                })
            }

        })
    }
}
}

</script>
