<template>
    <section style="width:100%;">
<div class="row" style="margin: 25px;">
    <div class="col-md-2 col-xs-12"><img src="../assets/img/logo.png" alt="E-devis" style="max-height: 3.75rem !important;"></div>
    <div class="col-md-8 col-xs-12" style="padding-top: 20px;"> <H2> {{ $store.state.SITE_SLOGAN }} </H2></div>
</div>



    <b-navbar class="navbar">
        <template #brand>
        </template>
        <template #start>
            <b-navbar-item @click="setPage(1)">
                <router-link to="/"  style="color:#fff !important; text-decoration: none;">  {{ $store.state.DEMANDES_PUBLIEES }}  </router-link>
            </b-navbar-item>
            <b-navbar-item @click="setPage(2)">
                <router-link to="/mesdemandes" style="color:#fff !important; text-decoration: none;"> {{ $store.state.MES_DEMANDES }} </router-link>
            </b-navbar-item>
            <b-navbar-item @click="setPage(3)">
                <router-link to="/mesoffres" style="color:#fff !important; text-decoration: none;"> {{ $store.state.MES_OFFRES }} </router-link>
            </b-navbar-item>

        </template>

        <template #end>

            <b-navbar-item tag="div" v-if="$store.state.USERID < 1 || !$store.state.USERID">
                <div class="buttons">
                    <a class="button is-primary" @click="isComponentModalActive = true">
                        <strong> {{ $store.state.SE_CONNECTER }} </strong>
                    </a>
                    <a class="button is-light">
                        {{ $store.state.S_ENREGISTRER }}
                    </a>
                </div>
            </b-navbar-item>

            <b-navbar-item tag="div"  v-if="$store.state.USERID > 0">
                <div class="buttons">
                    <a class="button is-primary" @click="isComponentModalActive = true"><i class="fa-solid fa-circle-user"></i>
                        <strong style="margin-inline-start: 9px;">  Mon profil</strong>
                    </a>
                    <a class="button is-light" @click="logOut()">
                        <i class="fa-solid fa-right-from-bracket"></i>  <strong style="margin-inline-start: 9px;">Déconnexion</strong>
                    </a>
                </div>
            </b-navbar-item>

            <b-navbar-item tag="span" @click="setLangue('ar')">
            <img src="../assets/img/ar.png" width="30" style="cursor:pointer;"/>
            </b-navbar-item>
            <b-navbar-item tag="span" @click="setLangue('en')">
                <img src="../assets/img/en.png" width="30" style="cursor:pointer;"/>
            </b-navbar-item>
            <b-navbar-item tag="span" @click="setLangue('fr')">
                <img src="../assets/img/fr.png" width="30" style="cursor:pointer;"/>
            </b-navbar-item>

        </template>
    </b-navbar>

    <b-modal
            v-model="isComponentModalActive"
            has-modal-card
            trap-focus
            aria-role="dialog" >          
            <Loginform @close="close"/>
                
           
        </b-modal>



       
        
    </section>

</template>

<script>
import Loginform from './Loginform.vue';  
import {  getUser }  from '../common'

import axios from "axios";


    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Appnav',
        components: {
            Loginform
        },
        props: {
            
        },
        data() {
            var USERID            
            return {
                isComponentModalActive: false,
                formProps: {
                                email: 'evan@you.com',
                                password: 'testing'
                            } ,
                            USERID                           
            }
        },
    methods :{
    toar()    {
        // eslint-disable-next-line no-undef
        this.$store.commit('toAr');
    },

    setPage(page) { this.$store.commit('setPage', page); },

    close(){  this.isComponentModalActive = false; console.log("fermer") ;},
    logOut()
      {
        axios.post('http://localhost:1515/logout', {} ,{ withCredentials:true}
            )
        .then(response => { 
          let result = response["data"];
          console.log("logout : ", result);
          if(parseInt(result) > 0)
          {
            location.reload();
          }
          else
          {
            this.$buefy.snackbar.open({
                    message: "Une erreur est survenue, vous n'êtes pas déconneter",
                    type: 'is-danger',
                    position: 'is-bottom',
                    actionText: 'Reéssayer',
                    indefinite: true,
                    onAction: () => {
                        this.logOut();
                    }
                })
          }
      
      })
      },


      setLangue(langue) {
        axios.post('http://localhost:1515/setlangue', {"langue":langue} ,{ withCredentials:true}).then(()=>{

    switch(langue) {
      case "ar" : {  this.$store.commit('toAr');  break; }
      case "fr" : {  this.$store.commit('toFr');  break; }
      case "en" : {  this.$store.commit('toEn');  break; }
      default : {  this.$store.commit('toFr');  break; }
    }

        });
      }
   
},
/*
computed: {
    acount () {
      return store.state.count;
    }
  },
*/
    async mounted(){
      this.USERID = await getUser();  
      console.log("add : ", this.USERID)    
    }
    }

</script>

<style>
.navbar {
    background: #0f7699 !important;
    margin-bottom: 0px;
    padding: 0px !important;
}

.navbar-item, .navbar-link { color: #fff !important; text-decoration: none;}

.navbar-brand  { background-color: #fff;
    border: solid #0f7699; }

    a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
    background-color: #0f7699 !important;
   
}

</style>
