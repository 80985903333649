import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
    state () {

      return {
        USERID: 0,
        PAGE : 1, // 0 : demandes publiées, 1 : mes demandes, 2 : mes offres
        SITE_SLOGAN : 'La Centrale des devis',
        SITE_DIRECTION : "ltr",
        DEMANDES_PUBLIEES : 'Demandes publiées',
        MES_DEMANDES : 'Mes demandes',
        MES_OFFRES : 'Mes offres',
        SE_CONNECTER : 'Se connecter',
        S_ENREGISTRER : "S'enregistrer",
        HOME : 'Accueil',

        SITE_COPYRIGHT : 'Dable You Net 2024  Tous droits réservé'
      }
    },
    mutations: {
      increment (state) {
        state.count++
      },
          setUserId(state, user) {  state.USERID = user;  }
      ,
      setPage (state, page) { state.PAGE = page;   },
      
      toAr(state) {
        state.DEMANDES_PUBLIEES = 'الطلبـات المنشورة';
        state.MES_DEMANDES = 'طلبـاتي';
        state.MES_OFFRES = 'عـروضي';
        state.SE_CONNECTER = 'تسجيـل الدخـول';
        state.S_ENREGISTRER = 'انشـاء حسـاب';
        state.HOME = 'الرئيسية';
        state.SITE_DIRECTION = 'rtl';
        state.SITE_COPYRIGHT = 'دابل يو نت 2024 كل الحقوق محفوضة';

      },

      toEn(state) {
        state.DEMANDES_PUBLIEES = 'Published requests'
        state.MES_DEMANDES = 'My requests';
        state.MES_OFFRES = 'My offers';
        state.SE_CONNECTER = 'Login';
        state.S_ENREGISTRER = 'Register';
        state.HOME = 'Home';
        state.SITE_DIRECTION = 'ltr';
        state.SITE_COPYRIGHT = 'Dable You Net 2024 All Right Reserved'

      },

      toFr(state) {
        state.DEMANDES_PUBLIEES = 'Demandes publiées';
        state.MES_DEMANDES = 'Mes demandes';
        state.MES_OFFRES = 'Mes offres';
        state.SE_CONNECTER = 'Se connecter';
        state.S_ENREGISTRER = "S'enregistrer";
        state.HOME = 'Accueil';
        state.SITE_DIRECTION = 'ltr';
        state.SITE_COPYRIGHT = 'Dable You Net 2024  Tous droits réservé';


      },

    },
    actions: {
      startTimer ({ commit }) {
      commit('setUserId', 1);
      }
    }
  })

//store.dispatch('startTimer')

  export { store };