<template>
  <div id="app" :dir="$store.state.SITE_DIRECTION">

 <Appnav/>
 <Breadcrumb :page="page"/>
<div class="row">
  <div class="col-md-2 d-none d-sm-block"></div>
  <div class="col-md-8"><Router-view /></div>
  <div class="col-md-2 d-none d-sm-block"></div>
</div>

<div class="row" style="position:absolute; bottom : 0px; padding:20px; width:100%;">
  <div class="col-md-4"></div>
  <div class="col-md-4"></div>
  <div class="col-md-4">  {{ $store.state.SITE_COPYRIGHT }} </div>
</div>

  </div>

  
</template>

<script>
import { store } from './store'
import Appnav from './components/Appnav.vue'
import  Breadcrumb  from './components/Breadcrumb.vue'
import { getUser }  from './common'

export default {
  name: 'App',
  data() {
    var page=1
    return {
      page
    }
  },
  components: {
    Appnav,
    Breadcrumb  },
methods: {
  
  setBreadCrumb(page) {
    this.page = page
  },

  async setUser() {  store.commit('setUserId', await getUser());  }
},
mounted() {
  this.setUser();
},


}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
